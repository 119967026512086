import Axios from 'axios';
let WEBCHAT_SCRIPT_ID = 'wisevoice_chat_parameters';
let addWebchatTimeout = null;
export function addWebchat(projectId) {
  Axios.get('/internal_api/webchat/clientKey', {
    params: { projectId: projectId },
  })
    .then((response) => {
      addToDOM(response.data.clientKey, projectId);
    })
    .catch((error) => {
      console.error('Response WEBCHAT error', error);
    });
}

export function addToDOM(clientKey, projectId) {
  if (clientKey === undefined) {
    return;
  }
  if (document.getElementById(WEBCHAT_SCRIPT_ID)) {
    document.getElementById(WEBCHAT_SCRIPT_ID).remove();
  }
  addWebchatTimeout = setTimeout(() => {
    const script = document.createElement('script');
    script.id = WEBCHAT_SCRIPT_ID;
    script.src = process.env.REACT_APP_WEBCHAT_URL;
    script.async = true;
    script.setAttribute('clientKey', clientKey);
    script.setAttribute('projectId', projectId);
    if (process.env.NODE_ENV === 'development') {
      script.setAttribute('isLocalCookie', true);
    }
    document.body.appendChild(script);
  }, 2000);
}

export function removeWebchat() {
  const script = document.getElementById(WEBCHAT_SCRIPT_ID);
  const webchat = document.getElementById('wisevoice_chat');
  if (script) {
    script.remove();
    webchat?.remove();
  }
  clearTimeout(addWebchatTimeout);
}
